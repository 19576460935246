<template>
  <div class="main">
    <div class="invoicet"><Head title="消息通知"></Head></div>
    <div class="Live-empty-list ">
      <div class="el">
        <el-table
            v-if="tableData.length > 0"
            :data="tableData"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column type="index" width="50" label="序号" />
          <el-table-column prop="noticeTitle" label="标题"> </el-table-column>
          <el-table-column width="104" label="操作">
            <template slot-scope="scope">
              <el-button
                  type="primary"
                  size="small"
                  @click="handleViewInvoice(scope.row.noticeId)"
              >查看
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="remarks" label="备注"> </el-table-column> -->
        </el-table>
        <div v-else class="Live-empty">您还没有收到的新消息</div>
      </div>
      <div class="pagination">
        <Pagination
            :total="search.total"
            :page.sync="search.page"
            :limit.sync="search.size"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getNoticeList } from '@/api/common'

import Head from './Head'
export default {
  name: 'Message',
  components: {
    Pagination,
    Head
  },
  data() {
    return {
      search: {
        page: 1,
        size: 10,
        total: 0 //分页
      },

      tableData: [
        // {
        //   title: '我的第一条标题',
        //   content: '内容',
        //   state:"完成"
        // }
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      //1 通知 2 公告
      const obj = {
        noticeType: 1,
        page: 1,
        size: 10
      }
      getNoticeList(obj).then((res) => {
        if (res.data.code === 0) {
          console.log(res)
          this.search.total = res.data.data.total // 共几条数据
          this.tableData = res.data.data.rows
        }
      })
    },
    handleViewInvoice(noticeId) {
      console.log('sddfsdf')
      this.$router.push({
        path: `/myCenter/noticeDetail?noticeId=${noticeId}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {

  background: #fff;
  padding-bottom: 20px;
  span {
    display: flex;
    // padding-bottom: 15px;
  }
  .Live-empty-list {
    list-style: none;
    margin: 0;
    padding: 0;
    // min-height: 62vh;
    .Live-empty {
      text-align: center;
      color: #c1c1c1;
      padding: 20px 0;
      margin-top: 15px;
    }
    .el-table {
      margin-top: 20px;
    }
    .pagination {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

.el{
  padding: 10px 20px;
  margin-top: 0px !important;
  // min-height: 62vh;

}
</style>